@import "../../Constantes";

.FCTagLabel {
  padding: 1px 10px;
  border-radius:5px;
  height:19px;
  line-height: 19px;

  // Pour compatibilité avec form CV
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}