.div_root {
  border: 1px solid #bdc3c7;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 10px;
}

.div_image {
  align-items: center;
  padding-left: 15px;
  display: flex;
}

.image_stack {
  padding: 10px 10px 10px 15px;
}

.div_root_input{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  padding-right: 10px;
}

.div_input {
  display:flex;
  flex-direction:column;
  padding:5px 0 5px 0;
  font-size: 0.75em;
}
.ant-input-number {
  background-color: #ffffff;
}