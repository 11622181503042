@import "../../../Constantes";

.FCDropdownLabel {
  width: 110px;
  display: inline-block;
  font-size: 13px;
  color:$main-font-color-2;

  // Pour compatibilité avec form CV
  text-align: left;
  text-transform: uppercase;
  margin-left:2px
}

.FCDropdown {
  padding-left:10px;
  border-radius: 5px;
  border: 1px groove $main-color-back-1;
  color: $main-font-color;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 25px;
  font-size: 13px;
}