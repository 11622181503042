@import "../../Constantes.scss";

body{
   font-family: 'Inter', sans-serif;
}

.dashboard{
  min-height: 100vh;
}

.absolute {
  position:absolute;
  width: 100%;
}

.relative {
  position: relative;
}

//Content --------------------
.contentDashboard{
  position: relative;
  background-color: $main-color-content;
  min-height: calc(100vh - $height-header - $height-footer);
}

.layoutContent {
  width: auto;
  top:5px;
  bottom:5px;
  right: 5px;
  left: 5px;
  background-color: $main-color-back-1;
  border-radius: 0;

  overflow: auto;
  overflow-x: hidden;
}
// end content ----------------

