@import "../../../Constantes";

.FCInputLabel {
  display: inline-block;
  font-size: 13px;
  color: #FFF;
  text-align: left;
  text-transform: uppercase;
  margin-left:2px
}

.FCInput {
  padding-left:10px;
  border-radius: 5px;
  color: $main-font-color;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 25px;
  font-size: 13px;

  border: 1px solid $main-font-color;
}

input[type="file"]::file-selector-button {
  background-color: $main-color-back-2;
  color: $main-font-color;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 11px;
  cursor:pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

input[type="file"]::file-selector-button:hover {
  filter: brightness(85%);
}

.horizontalFormInput{
  display: flex;
  flex-direction: row;
}

.verticalFormInput{
  display: flex;
  flex-direction: column;
}