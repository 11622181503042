@import "./Constantes.scss";

body {
  margin: 0;
  font-family: Montserrat-Regular, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $main-font-color;
  background-color: $main-color-back-2;
}

@media screen {

  html {
    min-width: 23cm;
  }

  .notes {
    margin-top: 1.5em;
    text-align: center;
    color: $main-font-color;
    font-size: 0.8em;
    font-weight: 600;
    opacity: 0.5;
    font-family: 'Open Sans', sans-serif;

    p {
      margin: 2em 0;
    }

    a {
      color: inherit;
    }

    .red {
      color: #e30e0e;
    }
  }

  html {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @-moz-document url-prefix() {
      & {
        font-size: 16px;
      }
    }
  }

  html, body, #root {
    min-height: 100vh;
  }
}

@media print {
  .div_print {
    padding: 0;
    margin: 0;
  }
  .notes {
    display: none;
  }
  .int {
    display: none;
  }
  .no-print {
    display: none;
  }
}

@page {
  margin: 0;
  size: A4;
}

h1 {
  font-size: 18px;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}