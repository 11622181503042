@import "../../../../Constantes";

.content-page-account {
  margin-top: 50px;
  width:100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.content-heading{
  text-align: center;
  margin-top: 30px;
}
