@import "../../Constantes.scss";

.errorPage {
  margin-top: 50px;
}

.div_logo_error {
  display:flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
}

.div_title_error {
  padding-top: 10px;
  display:flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: 20px;
}

.div_message{
  width: auto;
  flex: 1;
  display:flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid #ffffff22;
  background: $main-color-content;
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: .7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.div_message p{
  font-size: 12px;
}

// Petit écran
@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  .div_message {
    max-width: 350px;
  }
}

// Grand écran
@media screen and (min-width: $SIZE_SMALL_SCREEN) {
  .div_message {
    max-width: 400px;
  }
}