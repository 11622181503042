@import "../../Constantes.scss";

.login {
  color: $main-font-color;
  margin-top: 50px;
}

.div_logo {
  display:flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
}

.div_title {
  padding-top: 10px;
  display:flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
}

.div_authentification {
  flex: 1;
  width: auto;
  display:flex;
  flex-direction: column;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
  border: 1px solid #ffffff22;
  background: $main-color-content;
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: .7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.labelInput {
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  margin-left:2px;
  border: 1px solid #ffffff22;
}

.inputLogin {
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 25px;
  border: 1px solid #00000044;
}

.errorLogin {
  font-size: 13px;
  margin-top:15px;
  height:20px;
}

// Petit écran
@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  .div_authentification {
    max-width: 350px;
  }
}

// Grand écran
@media screen and (min-width: $SIZE_SMALL_SCREEN) {
  .div_authentification {
    max-width: 400px;
  }
}