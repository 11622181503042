@import "../../../Constantes";
// footer ----------------
.footerDashboard {
  height: $height-footer;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  background: $main-color-header;

  border-top: 1px;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-style: dashed;
}

.titleFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  .titleFooter {
    text-align: center;
    font-size: 11px;
    margin-top: 5px;
  }
  .footerDashboard {
    flex-direction: column;
  }
  .versionFooter {
    padding-top: 5px;
    text-align: center;
    margin-bottom: 5px;
    font-size: 9px;
  }
}

@media screen and (min-width: $SIZE_SMALL_SCREEN) {
  .footerDashboard {
    flex-direction: row;
  }
  .titleFooter {
    text-align: center;
    margin-left: auto;
    font-size: 13px;
  }
  .versionFooter {
    text-align: center;
    align-items: flex-end;
    margin: auto 25px auto auto;
    font-size: 11px;
  }
}
//end footer ---------
