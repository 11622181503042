@import "../../../Constantes";

$OPACITY_ALERT_MSG:0.15;
$POSITION_BOTTOM:60px;
$POSITION_ANIMATION_BOTTOM:30px;

.alertMsg {
  visibility: hidden;
  width: 400px;
  height: auto;
  margin-left: -200px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  padding: 16px;
  position: fixed;
  z-index: 999;
  left: 50%;
  bottom: $POSITION_BOTTOM;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 3px solid;
}

.alertMsgDivContent{
  display: flex;
  flex-direction: row;
}

.alertMsgTitle {
  width: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.alertMsgContent {
  width: 80%;
  text-align: left;
}

.alertMsgShow {
  visibility: visible;
  -webkit-animation: fadein 2s, fadeout 2s 8s;
  animation: fadein 2s, fadeout 2s 8s;
}

.errorAlertMsg {
  color:$ALIZARIN;
  background-color: rgba($ALIZARIN, $OPACITY_ALERT_MSG);
}

.successAlertMsg {
  color:$EMERALD;
  background-color: rgba($NEPHITIS, $OPACITY_ALERT_MSG);
}

.infoAlertMsg {
  color:$PETER_RIVER;
  background-color: rgba($PETER_RIVER, $OPACITY_ALERT_MSG);
}

.warningAlertMsg {
  color:$ORANGE;
  background-color: rgba($CARROT, $OPACITY_ALERT_MSG);
}


@-webkit-keyframes fadein {
  from {transform: translateY($POSITION_BOTTOM); opacity: 0;}
  to {transform: translateY($POSITION_ANIMATION_BOTTOM); opacity: 1;}
}

@keyframes fadein {
  from {transform: translateY($POSITION_BOTTOM); opacity: 0;}
  to {transform: translateY($POSITION_ANIMATION_BOTTOM); opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {transform: translateY($POSITION_ANIMATION_BOTTOM); opacity: 1;}
  to {transform: translateY($POSITION_BOTTOM); opacity: 0;}
}

@keyframes fadeout {
  from {transform: translateY($POSITION_ANIMATION_BOTTOM); opacity: 1;}
  to {transform: translateY($POSITION_BOTTOM); opacity: 0;}
}

