@import "../../Constantes";

.btn {
  margin-top: 15px;
  text-align: center;
  background-color: $main-color-back-2;
  color: $main-font-color-2;
  border-radius: 5px;
  border-style: none;
  font-weight: 700;
  font-size: 11px;
  cursor:pointer;
  text-transform: uppercase;
  padding: 10px;
  letter-spacing: 0.09em;
}

.btn:hover{
 filter: brightness(85%);
}