@import "../../../Constantes.scss";

//header -------------------------
.headerDashboard {
  height: $height-header;
  background-color: $main-color-header;

  border-top: 0;
  border-right: 0;
  border-bottom: 1px;
  border-left: 0;
  border-style: dashed;
}

.headerLogo {
  display: block;
  float: left;
  margin-top: calc($height-header / 2 - 22px);
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

.headerLogo > img {
  float: left;
}

.headerTitle {
  float: left;
  width: 115px;
  height: 20px;
  margin-top: calc($height-header / 2 - 10px);
  font-weight: 600;
  margin-left: 20px;
  cursor: pointer;
}

.menuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  width: 200px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}

.menuItem.active {
  background-color: #333;
}

.spacerHeader {
  font-weight: 100;
  background: $main-color-back-2;
}

.divCompte {
  margin-left: auto;
  margin-right: 40px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
}

.menuToggle {
  display: none;
  position: absolute;
  top: 22px;
  right: 15px;
  cursor: pointer;
  z-index: 9999;
}

.menuToggle .iconBar {
  display: block;
  width: 22px;
  height: 3px;
  background-color: $main-color-back-2;
  margin-bottom: 5px;
}

/* Styles pour les écrans de petite taille */
@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  .menuToggle {
    display: block; /* Afficher le bouton du menu hamburger */
  }
  .menuBar {
    display: none;
  }
  /* Styles pour afficher le menu lorsqu'il est ouvert */
  .menuBar.show {
    position: absolute;
    top: $height-header;
    right: 0;
    left: 0;
    display: flex;
    height: calc(100vh - $height-header);
    flex-direction: column;
    background-color: $main-color-header;
    z-index: 1000;
  }
  .spacerHeader {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
    height: 4px;
  }
  .menuItem {
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Styles pour les écrans de taille moyenne et grande */
@media screen and (min-width: $SIZE_SMALL_SCREEN) {
  .spacerHeader {
    display: block;
    margin: auto -1px;
    height: 35px;
    width: 3px;
    z-index: 1000;
  }
  .menuToggle {
    display: none; /* Masquer le bouton du menu hamburger */
  }

  .menuBar {
    display: flex;
    flex-direction: row;
    float: left;
    margin-left: 20px;
    width: calc(100% - 205px);
    height: $height-header;
  }
}