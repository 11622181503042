@import "../../../Constantes";

.react-datepicker__input-container input {
  width: inherit;
  padding-left:10px;
  border-radius: 5px;
  border: 1px groove $main-color-back-1;
  color: $main-color-back-1;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 25px;
  font-size: 13px;
}

.react-datepicker-wrapper {
  width: 100px;
}

.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 0;
}