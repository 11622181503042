.swatchFormColorPicker {
  background: #fff;
  border-radius: 1px;
  margin-bottom: 5px;
  margin-top: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
}

.popoverFormColorPicker {
  z-index: 2;
  margin-bottom: 5px;
}

.coverFormColorPicker {
  //position: 'fixed',
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bodyFormColorPicker {
  padding: 16px 16px 12px;
}

.colorFormColorPicker {
  width: 225px;
  height: 14px;
  border-radius: 2px;
  //background: color.hex,
}

.divFormColorPicker {
  align-items: center;
  width: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  // Pour compatibilité avec form CV
  font-family: -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 13px;
}