@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

$main-color: #2b333c;
$accent-color: #1879bb;
$light-color: #bfdaf0;

.page {

  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  h1,h2,h3,h4,h5,h6{
    text-transform: none;
  }

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  width: 21cm;
  height: 29.65cm;
  overflow: hidden;
  size: A4;
  font-size: 16px;

  background-color: white;
  color: $main-color;

  font-family: "Open Sans", sans-serif;

  display: flex;
  flex-direction: column;
  position: relative;

  .df-col {
    display: flex;
    flex-direction: column;
  }

  margin-top: 5px;
  box-shadow: rgba(50, 50, 50, 0.8) 0 10px 20px;

  .df-row {
    display: flex;
    flex-direction: row;
  }

  header {
    position: relative;
    z-index: 200;
    display: flex;
    flex-direction: column;

    .header-bg {
      background-color: $main-color;
      padding: 1em 2em 0;
      display: flex;
      flex-direction: column;
    }

    color: white;

    .header-content {
      align-items: center;
      display: flex;
      flex-direction: row;

      h1,
      h2 {
        font-weight: normal;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      .name {
        flex: 1;

        h1 {
          font-size: 1.8em;
          margin: 0;
        }

        h2 {
          margin-bottom: 0;
          margin-top: 0;
          font-size: 1.3em;
        }
      }

      .infos {
        text-align: right;
        position: relative;

        .placeholder {
          //display: none;
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(43, 51, 60, 0.8);
          padding: 0 0.5em;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          cursor: pointer;

          h3 {
            margin: 0;
            font-size: 1em;
          }
          p {
            margin: 0;
            font-size: 0.7em;
            opacity: 0.7;
            text-align: center;
          }
        }

        &:hover {
          .placeholder {
            display: flex;
          }
        }

        .info {
          margin: 0.1em;
          font-size: 0.8em;
          opacity: 0.8;

          .info-icon {
            margin: 0 0 0 0.75em;
          }
        }
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;

    &.row-exp {
      flex: 1;
      .main {
        flex: 20;
      }

      .col {
        flex: 7;
      }
    }

    &.row-form {
      .main {
        flex: 17;
      }

      .col {
        flex: 9;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;

    margin-bottom: 70px;

    .main {
      margin: 0 0.5em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .col {
      padding-top: 25px;
      padding-bottom: 1em;
      margin-bottom: -1.5em;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 960px;

      background-color: $light-color;
      position: relative;
      margin-top: -1.5em;

      .col-section {
        margin-bottom: 2em;
        padding: 0.1em 1em;
      }

      .col-barre {
        border-style: solid;
        border-width: 2px 0 0 0;
        margin-top: 13px;
        flex-grow: 1;
      }

      .col-title {
        margin: 0 0 0.5em;
        padding: 0.25em 0;
        text-align: left;
        text-transform: uppercase;
        font-size: 0.85em;
        color: $main-color;
        ;
          .red {
          color: #e30e0e;
        }
      }

      .langs {

        .lang {
          &:not(:last-child) {
            margin-bottom: 0.5em;
          }

          div {
            .icon {
              margin: 0 0.5em;
            }

            margin: 0.25em 0;
            font-weight: 600;
            color: $main-color;
            font-size: 0.9em;
            line-height: 1em;
          }

          .secondary {
            margin: 0;
            padding-left: 4em;
            font-size: 0.7em;
            line-height: 1em;
            font-weight: 400;
            opacity: 0.6;
          }
        }
      }

      .skills {
        display: flex;
        flex-direction: column;

        padding: 0 1em;

        .skill {
          &:not(:last-child) {
            margin-bottom: 0.5em;
          }

          .meta {
            display: flex;
            flex-direction: row;
            align-items: center;

            .title {
              margin: 0;
              flex: 1;
              font-size: 0.85em;
              color: $main-color;
              opacity: 0.85;
              white-space: pre-wrap;
            }

            .xp {
              text-align: right;
              font-size: 0.9em;
              font-weight: 600;
              font-variant: all-small-caps;
              color: $main-color;
              opacity: 0.6;
            }
          }

          .progress-bar-container {
            margin: 0.1em 0.1em;
            height: 0.1cm;
            background-color: $main-color;
            opacity: 0.5;

            .progress-bar {
              height: 100%;
              background-color: $accent-color;
            }
          }
        }
      }

      .projects {
        //padding-bottom: 0.5em;
      }

      .projet {
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: row;
        margin-bottom: 1em;

        &:not(:last-child) {
          margin-bottom: 0.75em;
        }

        .images {
          display: flex;
          flex-direction: column;
          justify-content: center;

          img {
            height: 40px;
          }

          img:not(:first-child) {
            margin-top: -25%;
          }
        }

        .proj-desc {
          margin-left: 0.75em;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .proj-title {
            margin: 0 0 0.45em;
            font-weight: 700;
            color: $main-color;
            opacity: 0.8;
            font-size: 0.85em;
            line-height: 1em;
          }

          .secondary {
            margin: 0;
            font-size: 0.75em;
            line-height: 1em;
            font-weight: 400;
            opacity: 0.6;
          }
        }
      }
    }

    .section {
      margin: 0.5em;

      .sec-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.80em;

        .sec-icon {
          font-size: 1.75em;
          opacity: 0.8;
          width: 2rem;
        }

        h2 {
          font-variant: small-caps;
          font-size: 1.75em;
          font-weight: 600;
          margin: 0 0 0 0.3em;
          color: $main-color;
        }
      }

      .sec-content {
        position: relative;
        margin-left: 1rem;
        margin-top: 0.4em;
        padding-left: 1rem;

        &::before {
          content: "";
          position: absolute;
          border-left: solid 2px $accent-color;
          opacity: 0.6;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }

      .date {
        font-weight: 500;
        font-size: 0.85em;
        line-height: 0.8em;

        color: $accent-color;

        font-variant: all-small-caps;
        text-transform: uppercase;

        text-align:right;
        justify-content: space-between;

        .from,
        .to {
          font-variant: normal;
          font-weight: 600;
          margin: 0 0.25em;
        }
      }
    }
  }

  .section.experiences {
    margin-top: 0.5em;
    margin-right: 1em;

    .experience {

      margin-bottom: 10px;

      &::before {
        content: " ";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $accent-color;
        opacity: 1;
        position: absolute;
        left: calc(-1em - 2px);
        top: 1.3em;
      }

      &:not(:last-child) {
        margin-bottom: 1em;
      }

      position: relative;

      .header2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;;

        .title {
          color: $main-color;
          margin: 0;
          //padding-left: 0.3em;
          white-space: nowrap;
        }

        p {
          margin-bottom: 0;
          margin-top: 0.25em;
          padding-left: 0.5em;
        }
      }

      .exp-content {
        padding-left: 0.5em;
        margin-top: 0.25em;
      }

      .secondary {
        //text-transform: uppercase;
        opacity: 0.8;
        color: $main-color;
        font-size: 0.85em;

        margin: 0 0 0.15em;

        .company {
          font-weight: 700;
        }
      }

      .description {
        padding-left: 0;
        margin: 0;
        margin-bottom: 1em;
        //font-size: 0.95rem;
        font-size: 0.785em;
        opacity: 0.8;
        white-space: pre-wrap;
        line-height: 1.1em;
        text-align: justify;
      }

      .activities {
        padding-right: 0.5em;

        .activity {
          margin-bottom: 0.25em;

          .act-title {
            margin: 0;
            font-size: 0.9em;

            &::before {
              content: "-";
              margin-right: 0.5em;
            }
          }

          .description {
            padding-left: 1em;
          }
        }
      }

      .technologies {
        display: flex;
        flex-direction: row;
        font-size: 0.75em;

        p {
          margin: 0;
        }

        .tech-title {
          font-weight: 550;
          white-space: nowrap;
        }

        .tech-list {
        }
      }
    }
  }

  .formations {
    .formation {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 0.75em;
      }

      .form-content {
        h4 {
          font-weight: 600;
          font-size: 0.9rem;
          position: relative;
          line-height: 1em;
          margin: 0.4em 0;
          white-space: pre-wrap;

          &::before {
            content: " ";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $accent-color;
            position: absolute;
            left: calc(-1rem - 2px);
            top: calc(50% - 3px);
          }
        }

        .secondary {
          opacity: 0.8;
          color: $main-color;
          font-size: 0.8em;
          line-height: 1em;

          margin: 0;
          margin-bottom: 1em;
          padding-left: 0.5em;
        }
      }
    }
  }

  .logos {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;

    img {
      max-height: 50px;
    }
  }

  .logo-line {
    padding: 0.255em 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .logo-bubble {
    border-radius: 50%;
  }

  footer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 600;

    .footer-container {
      color: white;
      background-color: $main-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1em 0.5em;
      height: 2em;

      // HACK fixing a glitch in firefox
      margin-top: -3px;

      a {
        color: inherit;
        text-decoration: none;
      }

      .hobbies {
        opacity: 0.6;

        .icon {
          font-size: 1.3em;
          margin: 0 0.5em;
        }

        p {
          margin: 0;
        }

        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        font-size: 0.7em;
      }

      .links {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5em;
        font-size: 1.3em;

        .link {
          margin: 0 0.5em;
        }

        .social-tag {
          font-size: 0.7em;
          font-weight: 600;
          margin: 0 0.5em;
          opacity: 0.6;
        }
      }
    }
  }
}
