@import "../../../Constantes";

.FCTextAreaLabel {
  display: inline-block;

  text-align: left;
  text-transform: uppercase;
  margin-left:2px;
  font-size: 13px;
}

.FCTextArea {
  padding-left:10px;
  border-radius: 5px;
  border: 1px groove $main-color-back-1;
  color: $main-font-color;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 13px;
  width: 300px;
}